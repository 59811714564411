import React from "react";

const Calendy = (props) => {
  React.useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    script.setAttribute('async', 'true')
    head.appendChild(script);
  }, []);
  return (
    <div
      className="calendly-inline-widget"
      data-url='https://calendly.com/jivigya-homeopathic-clinic/30min'
      style={{ minWidth: '320px', height: '630px' }}
    />
  )
};

export default Calendy;
