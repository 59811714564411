import React from "react";

const Footer = () => {
  const footerLinksStyle = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textDecoration: "none",
    position: "relative",
  };
  return (
    <>
      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="social_profile col-10 mx-auto d-flex justify-content-center">
              <ul>
                <li>
                  <a href="#" style={{ footerLinksStyle }}>
                    <i class="fab fa-facebook fa-xl"></i>
                  </a>
                </li>
                <li>
                  <a href="#" style={{ footerLinksStyle }}>
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" style={{ footerLinksStyle }}>
                    <i class="fab fa-google-plus-g"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/jivigyahomeo/" style={{ footerLinksStyle }}>
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-xs-12">
            <p class="copyright">
              Jivigya Homeopathic Clinic © 2022 | Contact Number : 7049900456{" "}
              <a href="#"></a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
