import React from "react";
import { NavLink, Link } from "react-router-dom";
import web from "../src/images/med.gif";
import Commom from "./Common";

const Home = () => {
    return (
        <>
<section id="header" className="d-flex align-items-center">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <h1>
                    {"Your best health partner "}
                    <strong className="brand-name">Jivigya Homeopathic Clinic</strong>
                  </h1>
                  <h2 className="my-3">
                  Gentle Healing for a Healthier You
                  </h2>
                  <div className="mt-3">
                    <NavLink to={"/contact"} className="btn-get-started ">
                    Schedule Appointment
                    </NavLink>
                  </div>
                </div>

                <div className="col-lg-6 order-1 order-lg-2 header-img">
                  <img
                    src={web}
                    className="img-fluid animated"
                    alt="Commom img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    )
}

export default Home

// <Commom
// name="Your best health partner "
// imgsrc={web}
// center_name="Jivigya Homeopathic Clinic"
// txt="Gentle Healing for a Healthier You"
// btname="Schedule Appointment"
// visit="/contact"
// />