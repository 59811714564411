import React from "react";
import { NavLink } from "react-router-dom";

const handleCollapse = () => {
  console.log("handleCollapse");
  var nav = document.getElementById("navbarSupportedContent");
  var btn = document.getElementById("navbarBtn");
  nav.classList.remove("show");
  //   nav.classList.remove("collapse");
  //   nav.classList.add("collapsing");
  //   setTimeout(() => {
  //     nav.classList.add("collapse");
  //     nav.classList.remove("collapsing");
  //   }, 500);

  btn.classList.add("collapsed");
};

const Navbar = () => {
  return (
    <>
      <div className="container-fluid nav_bg">
        <div className="row">
          <div className="col-10 mx-auto">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <NavLink
                  activeClassName="menu_active"
                  className="navbar-brand"
                  to="/"
                >
                  <img src= "src/static/jivigya_logo.svg" alt="" />
                  Jivigya Homeo
                </NavLink>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  id="navbarBtn"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <NavLink
                        exact
                        activeClassName="menu_active"
                        className="nav-link"
                        aria-current="page"
                        onClick={handleCollapse}
                        to="/"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeClassName="menu_active"
                        className="nav-link"
                        to="/about"
                        aria-current="page"
                        onClick={handleCollapse}
                      >
                        About
                      </NavLink>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Consultation
                      </a>
                      <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                        <li><a class="dropdown-item" href="/onlineConsultation">Online Consultation</a></li>
                        <li><a class="dropdown-item" href="/offlineConsultation">Offline Clinic Consultation</a></li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeClassName="menu_active"
                        className="nav-link"
                        aria-current="page"
                        to="/reviews"
                        onClick={handleCollapse}
                      >
                        Reviews
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
