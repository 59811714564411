import React from "react";
import { Carousel } from "react-bootstrap";
import Testimonial from "./Testimonial";
import { testimonialsData } from "./testimonial-data";

const Reviews = () => {
  console.log(testimonialsData);

  return (
    <>

      <section id="header" className="d-flex align-items-top">
        <div className="container-fluid ">
          <div className="row">
            <h1 className="text-center">Testimonials From Our Patients</h1>
          </div>
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-10 mx-auto d-flex justify-content-center" >

                  <Carousel>
                    <Carousel.Item interval={3000}>
                      <Testimonial
                        img="new.jpg"
                        review="I got diagnosed with 3 kidney stone again after 2 years. The pain of it was unbearable and I couldn’t be able to do anything due to this stone problem. I got really nervous and tensed that what’s gonna happen now as I have to travel back again to London. I was really depressed that what if I got sudden pain there. How I am gonna handle the situation like that multiple questions were stucking in my mind. Then I discussed in detail about these concerns with Dr.Sanskriti who is specialised in homeopathy treatment. The best way about her is that the way she asked me as a person how I am, what activities I do in daily life, like that numerous question answer session which is related with me as a human. Then atleast she used to say Ok! done with the session Mujhe mil gyi medicine jo tumhe sahi kr degi. I got shocked how easily it happened.. the best thing which I liked about Doctor Saab is the way how she treat patients very comfortably. Then she given me 6months chotii-chotti mithi medicines  which i took to London with me..and I must say it’s been 8 months now and for not a single time I got kidney stone pain! I am thankful to the Dr. saab for all your guidance and help. I really appreciate your practice and knowledge which you have in your field."
                        name="Umang Saxena"
                        occupation="Student, London"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <Testimonial
                        img="shefali.jpeg"
                        review="Dr. Sanskriti is a very active listener, her style not only focuses on the major problems but goes in depth of every single symptom . She noted my history and connected with me in such a short period of time that now I can share my personal problems with her without any hesitation . She even takes my follow up time to time and even in off times i can connect with her and she treats me well in that off time too . Her treatment is working as a miracle for me . I am grateful to have her as my doctor."
                        name="Shefali"
                        occupation="Psychiatrist, Bhopal"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <Testimonial
                        img="divas.jpg"
                        review="She is a very sensible and understanding doctor, who not only understood me but also the root cause of my problem. After discussing numerous question. She prescribed me medicine, which helped me overcome my troubles & issues which I was dealing from a long duration of time. Very grateful & happy for the care I received."
                        name="Divas"
                        occupation="Businessman, Indore"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <Testimonial
                        img="woman.png"
                        review="She is not only an Excellent Doctor , she is simple, superb Human being, Sober, approachable, a Great Social Worker, friendly approach with smiling face with his selfless service . Always amazing treatment. She is an extraordinary intelligent Doctor with human values"
                        name="Tanvi"
                        occupation="Student, Indore"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <Testimonial
                        img="ritik.jpg"
                        review="I got to Sanskriti through a friend, I have been using Homeopathic medicines since my childhood so obviously I had an inclination towards this, but the way Sanskriti approaches the root cause of the problem is amazing. The questionnaire she had makes you realise how passionate and dedicated she is about her work. She is a great personality, I wish her all the best for her future endeavours."
                        name="Ritik Anand"
                        occupation="Software Engineer, Bangalore"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <Testimonial
                        img="aditya.png"
                        review="Dr. Sanskriti is a remarkable homeopathic doctor! Her passion for the field and dedication to her patients is truly inspiring. Initially I was hesitant to try homeopathy but just because of Doctor Madam I finally decided to give it a try. And from since then it has done miracles in my health. Whether it be a normal viral or untreatable issues like motion sickness, she has got it all covered. Her expertise in treating general health and well-being is evident in her results, and her patients are always left feeling heard and understood. Whether it's a physical issue or a more emotional one, this doctor is dedicated to finding the root cause and creating a customized treatment plan. She does seems to have a magic door through which she reaches to the very depth of her patients life and the issues they are dealing with :) It's clear that her priority is the overall health and happiness of her patients, making her an exceptional practitioner in the field of homeopathy given such a young age."
                        name="Aditya S Rathore"
                        occupation="Software Engineer, Bangalore"
                      />
                    </Carousel.Item>

                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reviews;
