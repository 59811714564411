import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Reviews from './Reviews';
import Navbar from './Navbar';
import Footer from './Footer';
import OnlineConsultation from './OnlineConsultation';
import OfflineConsultation from './OfflineConsultation';

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/reviews" element={<Reviews />} />
        <Route exact path='/onlineConsultation' element={<OnlineConsultation/>}/>
        <Route exact path='/offlineConsultation' element={<OfflineConsultation/>}/>
      </Routes>
      <Footer />
    </>

  )
}

export default App;
