import React from "react";
import Commom from "./Common";
import web from "../src/images/intro_img.png";
import { NavLink } from "react-router-dom";

const About = () => {
  return (
    <>
      <section id="header" className="d-flex align-items-center">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <h1>
                    {""}
                    <strong className="brand-name">
                      {" "}
                      {"Dr. Sanskriti Choudhary"}{" "}
                    </strong>
                  </h1>
                  <p className="intro my-3">
                    Dr. Sanskriti, a highly qualified and experienced
                    homeopathic doctor, has completed a Bachelor of Homeopathic
                    Medicine and Surgery from SKRP Homeopathic Medical College,
                    Indore. With a passion for holistic healing, Dr. Sanskriti
                    believes in treating the root cause of illness, rather than
                    just its symptoms. She has extensive knowledge of the
                    principles and practices of homeopathy, and has a proven
                    track record of successfully helping patients achieve
                    optimal health. Whether you are looking to improve your
                    overall well-being or seeking relief from specific health
                    issues, Dr. Sanskriti is dedicated to providing personalised
                    and effective care.
                  </p>
                  <div className="mt-3">
                    <NavLink to="/contact" className="btn-get-started ">
                      Schedule Appointment
                    </NavLink>
                  </div>
                </div>

                <div className="intro_img_div mx-auto d-flex justify-content-right col-lg-6 order-1 order-lg-2">
                  <img src={web} className="intro_img" alt="Commom img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
