import React from "react";
import { Carousel } from "react-bootstrap";

class Testimonial extends React.Component {
  render() {
    console.log("hello");
    console.log(this.props);
    return (
      //   <div className="item carousel-item active" data-bs-interval="3000">
      //     <div class="img-box">
      //       <img src={this.props.imgUrl} alt="" />
      //     </div>
      //     <p class="testimonial">{this.props.review}</p>
      //     <p class="overview">
      //       <b>{this.props.name}</b>, {this.props.occupation}
      //     </p>
      //   </div>
      <div className="item carousel-item active" data-bs-interval="3000">
        <div class="img-box review_img">
          <img src={this.props.img} alt="" />
        </div>
        <p class="testimonial">
          {this.props.review},
        </p>
        <p class="overview">
          <b>{this.props.name}</b>, {this.props.occupation}
        </p>
      </div>
    );
  }
}

export default Testimonial;
