import React, { useState } from "react";
import Calendy from "./Calendy";

const Contact = () => {
  
  return (
    <>
      <div className="my-5">
        <h1 className="text-center"> Contact Us </h1>
      </div>
      <div className="container contact_div">
        <div className="row">
          <div className="col-md-6 col-10 mx-auto">
          <p style={{'text-align': 'center'}}>Please select the slot for your Appointment from calender below</p>
            <hr/>
            <br />
            <Calendy/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
