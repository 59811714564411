import React from "react";
import Calendy from "./Calendy";

const OnlineConsultation = () => {
  
  return (
    <>
      <div className="my-5">
        <h1 className="text-center"> Contact Us </h1>
      </div>
      <div className="container contact_div">
        <div className="row">
          <div className="col-md-6 col-12 mx-auto">
            <p style={{'text-align': 'center'}}>Please select the slot for your Online Consultation from calender below</p>
            <hr/>
            <br />
            <Calendy/>
            {/* <div className="row">
              <p style={{'text-align': 'center'}}>You will receive a confirmation call from us regarding your appointment. The appointment can take place via whatsApp, Meet or Zoom call.</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineConsultation;
